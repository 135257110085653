import { useEffect, useRef } from 'react'

const useEffectOnce = (effect: () => unknown, trigger?: boolean) => {
  const hasRun = useRef(false)
  const effectRef = useRef(effect)

  useEffect(() => {
    if (trigger !== undefined && !trigger) return
    if (hasRun.current) return

    hasRun.current = true
    const cleanup = effectRef.current()

    return () => {
      if (cleanup && typeof cleanup === 'function') {
        cleanup()
      }
    }
  }, [trigger])
}

export default useEffectOnce
